// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bli-medlem-index-js": () => import("./../../../src/pages/bli-medlem/index.js" /* webpackChunkName: "component---src-pages-bli-medlem-index-js" */),
  "component---src-pages-boka-index-js": () => import("./../../../src/pages/boka/index.js" /* webpackChunkName: "component---src-pages-boka-index-js" */),
  "component---src-pages-coacher-index-js": () => import("./../../../src/pages/coacher/index.js" /* webpackChunkName: "component---src-pages-coacher-index-js" */),
  "component---src-pages-kurser-index-js": () => import("./../../../src/pages/kurser/index.js" /* webpackChunkName: "component---src-pages-kurser-index-js" */),
  "component---src-pages-nyheter-index-js": () => import("./../../../src/pages/nyheter/index.js" /* webpackChunkName: "component---src-pages-nyheter-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-course-post-js": () => import("./../../../src/templates/course-post.js" /* webpackChunkName: "component---src-templates-course-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-naprapat-page-js": () => import("./../../../src/templates/naprapat-page.js" /* webpackChunkName: "component---src-templates-naprapat-page-js" */),
  "component---src-templates-pt-page-js": () => import("./../../../src/templates/pt-page.js" /* webpackChunkName: "component---src-templates-pt-page-js" */),
  "component---src-templates-team-post-js": () => import("./../../../src/templates/team-post.js" /* webpackChunkName: "component---src-templates-team-post-js" */)
}

