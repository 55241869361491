import { extendTheme } from "@chakra-ui/react";

const heading = '"Montserrat", sans-serif';
const body = '"Roboto", sans-serif';

// Let's say you want to add custom colors
const customTheme = {
  config: {
    useSystemColorMode: true,
    initialColorMode: "dark",
  },
  fonts: {
    heading,
    body,
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  styles: {
    global: {
      ".content": {
        h1: {
          fontFamily: heading,
          fontSize: "4xl",
          mb: "4",
        },
        h2: {
          fontFamily: heading,
          fontSize: "3xl",
          mb: "4",
        },
        h3: {
          fontFamily: heading,
          fontSize: "2xl",
          mb: "4",
        },
        h4: {
          fontFamily: heading,
          fontSize: "1xl",
          mb: "3",
        },
        h5: {
          fontFamily: heading,
          fontSize: "xl",
          mb: "3",
        },
        h6: {
          fontFamily: heading,
          fontSize: "lg",
          mb: "2",
        },
        img: {
          pb: "2",
        },
        ol: {
          pl: "4",
          mb: "2",
        },
        p: {
          fontSize: "md",
          lineHeight: "1.5",
          mb: "2",
        },
        a: {
          fontWeight: 500,
          textDecoration: "underline",
        },
      },
    },
  },
};

export default extendTheme({ ...customTheme });
